import { createSlice } from "@reduxjs/toolkit";

const initialServiceState = {
    services: [],
    timeSlots: [],
    removedTimes: []
}

const serviceSlice = createSlice({
    name: 'service',
    initialState: initialServiceState,
    reducers: {
        replace(state, action) {
            state.services = action.payload.services;
        },
        replaceTimes(state, action) {
            state.timeSlots = action.payload.timeSlots;
        },
        removeFromTimes(state, action) {
            const start = action.payload.start;
            const end = action.payload.end;
            state.removedTimes.push({start, end});
        },
        default(state) { return state }
    }
});

export const serviceActions = serviceSlice.actions;
export default serviceSlice;