import { useContext} from "react";
import { ThemeContext } from "../../context/ThemeContext";
import CircleImage from "./CircleImage";

function TopBanner({logo, pages, currentPage}){
    const theme = useContext(ThemeContext);
    const {dark} = theme;
    
    const circles = pages.map(c => <CircleImage key={c.name} image={c.image} name={c.name} page={c.page} active={currentPage}/>)

    return (
        <div className={`w-full ${currentPage===0?"h-1/6":"h-1/4"} rounded-t-xl border-t-2 border-x-2 border-accent ${dark?"bg-black ":"bg-white "}`}>
            <div className={` border-b-4 w-full h-full ${dark?"border-b-white":"border-b-black"}`}>
            <div className={`w-full  ${currentPage===0?"h-full":"h-5/6"} flex justify-center items-center`}>
            <img src={logo} className="m-auto " alt="Logo" />
            </div>
            <div className="h-1/6 md:w-3/5 w-3/4 flex justify-between mx-auto mt-2 md:pt-0 sm:mt-4 sm:pt-2 md:mt-2">
                {currentPage!== 0 && circles}
            </div>
            </div>
        </div>
    )
}

export default TopBanner;