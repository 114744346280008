import { useContext} from "react";
import { ThemeContext } from "../../context/ThemeContext";

function TitleColonInfo({title, info}){
    const theme = useContext(ThemeContext);
    const {dark} = theme;
    return(
        <div className=" py-2 border-b border-neutral-300">
          <p className={`md:text-sm text-xs font-semibold ${dark?"text-neutral-300":"text-neutral-300"}`}>{title}:</p>
          <p className={`md:text-sm text-xs ${dark?"text-white":"text-neutral-900"}`}>{info}</p>
        </div>
        )
}

export default TitleColonInfo;