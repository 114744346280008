import { useContext} from "react";
import { ThemeContext } from "../../context/ThemeContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";

function SmCalTitle({name}){
    const theme = useContext(ThemeContext);
    const {dark} = theme;
    const {width} = useWindowDimensions();
    return(
<span className={` text-center  font-normal ${dark? "text-neutral-300":"text-black"}`}>
{width>=1100?name:name[0]}
</span>
    )
}

export default SmCalTitle;