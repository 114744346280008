import useWindowDimensions from "../hooks/useWindowDimensions";
import ColouredSolidButton from "./UI/ColouredSolidButton";
import { useNavigation } from "react-router-dom";

function ButtonHolder ({backButton = null, forwardButton =null, submit = false}){
  const {height} = useWindowDimensions();
  const navigation = useNavigation();
  const isSubmitting = navigation.state === 'submitting';
    return(
        <div className="absolute bottom-0 w-full flex justify-center mb-6">
        <div className={`${height>700?"w-2/3":"w-full mx-8"} flex  ${backButton!== null? "justify-between":"justify-end"}`}>
          {backButton!==null&&<div className={`${height>700?"w-2/6 md:w-1/6":"w-1/12"}`}>
            <ColouredSolidButton text="Back" colour="bg-red-1000" onClick={backButton}/>
          </div>}
          {forwardButton!==null&&<div className={`${height>700?"w-2/6 md:w-1/6":"w-1/12"}`}>
            <ColouredSolidButton text={isSubmitting?"Submitting":"Next"} disabled={isSubmitting} colour="bg-green-1000" onClick={forwardButton} type={submit?"submit":"button"}/>
          </div>}
        </div>
        </div>
    )
}

export default ButtonHolder;