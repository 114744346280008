import { useNavigate } from "react-router-dom";
import { Fragment, useContext, useEffect, useState } from "react";
import { ThemeContext } from "../context/ThemeContext";
import BaseBooking from "../components/BaseBooking";
import MediumDropdown from "../components/UI/MediumDropDown";
import SmallCalendar from "../components/Calendar/SmallCalendar";
import CapsuleButton from "../components/UI/CapsuleButton";
import { useDispatch, useSelector } from "react-redux";
import { appointmentActions } from "../store/appointment-slice";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { GetTimeSlots } from "../store/service-actions";
import { GetServices } from "../store/service-actions";
import { GetSites } from "../store/organisation-actions";
import * as c from '../signal';

function ServicePage(){
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const services = useSelector((state) => state.service.services);
  const slots = useSelector((state) => state.service.timeSlots);
  const taken = useSelector((state) => state.service.removedTimes);
  const siteId = useSelector((state) => state.currentUser.site);
  const sites = useSelector((state) => state.organisation.sites);
  const { dark } = useContext(ThemeContext);
  const {width, height} = useWindowDimensions();
  const [date, setDate] = useState(new Date());
    const [timeSelect, setTimeSelect] = useState(false);
    const [serviceSelect, setServiceSelect] = useState(true);
    const [time, setTime] = useState();
    const [smallPages, setSmallPages] = useState(0)
    const [options, setOptions] = useState([{name:"loading",id:0,value:""}])
    const [siteOptions, setSiteOptions] = useState([{name:"loading",id:0,value:""}])
    const [timeOptions, setTimes] = useState([])
    const [site, setSite] = useState(siteOptions[0].value);
    const [service, setService] = useState(options[0].value);

    c.default();

    useEffect(()=>{
      dispatch(GetServices());
      dispatch(GetSites());
    },[])

    useEffect(()=>{
      if(siteId!==null){
      dispatch(GetTimeSlots({siteId:siteId,date:new Date()}));
      }
    },[siteId])

    useEffect(()=>{
      if(services !== undefined && services.length !== 0){
        const m = services.map(s => {return{name:s.name,id:s.id,value:{id:s.id,name:s.name}}});
        setOptions(m);
        changeService(m[0])
      }
    },[services])
    
    useEffect(()=>{
      if(sites !== undefined && sites.length !== 0){
        console.log(sites)
        const m = sites.map(s => {return{name:s.name,id:s.id,value:{id:s.id,name:s.name}}});
        setSiteOptions(m);
        changeSite(m[0])
      }
    },[sites])

    useEffect(()=>{
      if(date !== undefined && date !== null){
        const list = removeOverlappingTimes(taken,slots);
        const reduced = list.filter(s => new Date(s.slotStart).getDate() === date.getDate()&&s.serviceId === service.id);
        console.log(reduced);
        const m = reduced.map(s => {return{name:(new Date(s.slotStart).getHours() <10 ?"0"+new Date(s.slotStart).getHours():new Date(s.slotStart).getHours())+":"+(new Date(s.slotStart).getMinutes() <10 ?"0"+new Date(s.slotStart).getMinutes():new Date(s.slotStart).getMinutes()),id:new Date(s.slotStart),value:s.slotStart,disabled:false}});
        setTimes(m);
      }
    },[date, taken])

    function removeOverlappingTimes(removals, slots){
      const newSlots = slots;
      if(removals !== undefined){
      removals.forEach(element => {
        const start = new Date(element.start);
        const diff = new Date(element.end) - start;
        var diffMins = Math.round(((diff % 86400000) % 3600000) / 60000);

        for (let index = 0; index < diffMins; index++) {
          const comp = new Date(start.getTime() + index*60000);
          
          newSlots.filter(s => new Date(s.slotStart) !== comp || new Date(s.slotEnd) !== comp);
        }
      });
    }
      return newSlots;
    }

    function changeSlots(date){
      dispatch(GetTimeSlots({siteId:siteId,date:date}));
    }

    function setDay(val){
      if(val!== "none"){
        setTimeSelect(true);
        setServiceSelect(false);
        setDate(new Date(val));
        console.log(val)
      }
    }

    function chooseTime(e){
      setTime(e);
    }

    function backCalendar(){
      setTimeSelect(false);
  }
  function backService(){
    setServiceSelect(true);
  }

  function toCalendar(){
    setSmallPages(1);
  }
  function toService(){
    setSmallPages(0);
  }
  
  function changeService(val){
    setService(val)
    setTimeSelect(false);
  }

  function changeSite(val){
    setSite(val)
    setTimeSelect(false);
  }

  function enter(){
    if(service !== "" && date !== undefined && time !== undefined){
      const at = time.toString();
    dispatch(appointmentActions.inputService({service:service.id,at:at,serviceName:service.name}));
    navigate("/conf")
    }
  }
  
    const times = timeOptions.map(t => <CapsuleButton key={t.id} name={t.name} onClick={chooseTime} value={t.value} active={time} disabled={t.disabled}/>)

    return(
        <BaseBooking page={3} backButton={()=>{navigate("/contact")}} forwardButton={enter}>
        <div className="flex lg:items-center ">
        <div className={`w-full h-full ${height>600?"md:mt-20 mt-10":"mt-8"} `}>
            {width>=900? <div className={`rounded-lg container  w-full h-4/5 border grid md:grid-cols-2 grid-cols-3 border-neutral-300  ${dark?"bg-neutral-500 ":"bg-white"}`}>
              <div className=" h-full border-r border-neutral-300">
                <p className="text-[16px] text-center py-4 text-neutral-300 font-semibold border-neutral-300 border-b">Select service, date and time</p>
                <div className="p-4 pt-6">
                {serviceSelect === true ? <MediumDropdown options={options} onChange={changeService}/>:
                <div className={`px-2 py-1 w-full border flex justify-between items-center border-neutral-300 z-10`}>
                  <svg width="25" height="25" viewBox="0 0 25 25" className="stroke-neutral-300 fill-none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M 9.45 5.25 H 7.35 C 6.793 5.25 6.2589 5.4713 5.8651 5.8651 C 5.4713 6.2589 5.25 6.793 5.25 7.35 V 19.95 C 5.25 20.507 5.4713 21.0411 5.8651 21.4349 C 6.2589 21.8288 6.793 22.05 7.35 22.05 H 17.85 C 18.407 22.05 18.9411 21.8288 19.3349 21.4349 C 19.7288 21.0411 19.95 20.507 19.95 19.95 V 7.35 C 19.95 6.793 19.7288 6.2589 19.3349 5.8651 C 18.9411 5.4713 18.407 5.25 17.85 5.25 H 15.75 M 9.45 5.25 C 9.45 5.807 9.6712 6.3411 10.0651 6.7349 C 10.4589 7.1288 10.993 7.35 11.55 7.35 H 13.65 C 14.207 7.35 14.7411 7.1288 15.1349 6.7349 C 15.5288 6.3411 15.75 5.807 15.75 5.25 M 9.45 5.25 C 9.45 4.693 9.6712 4.1589 10.0651 3.7651 C 10.4589 3.3713 10.993 3.15 11.55 3.15 H 13.65 C 14.207 3.15 14.7411 3.3713 15.1349 3.7651 C 15.5288 4.1589 15.75 4.693 15.75 5.25 M 9.45 14.7 L 11.55 16.8 L 15.75 12.6" />
                  </svg>
                    <p className={`text-sm ${dark?"text-white":"text-black"}`}>{(service.name)}</p>
                    <button onClick={backService}>
                      <svg width="25" height="25" viewBox="0 0 25 25" className={`${dark?"fill-white":"fill-black"}`} xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.9825 4.48295C17.2131 4.24417 17.489 4.05372 17.794 3.92269C18.099 3.79167 18.427 3.72271 18.759 3.71982C19.0909 3.71694 19.4201 3.78019 19.7274 3.90589C20.0346 4.0316 20.3138 4.21723 20.5485 4.45196C20.7832 4.68669 20.9689 4.96582 21.0946 5.27306C21.2203 5.5803 21.2835 5.9095 21.2806 6.24145C21.2777 6.5734 21.2088 6.90145 21.0778 7.20646C20.9467 7.51147 20.7563 7.78733 20.5175 8.01795L19.5263 9.0092L15.9913 5.4742L16.9825 4.48295ZM14.2237 7.2417L3.75 17.7154V21.2504H7.285L17.76 10.7767L14.2225 7.2417H14.2237Z"/>
                      </svg>
                    </button>
                    </div>}                
                    {serviceSelect === true && sites.length >1 ? <div className="mt-4"><MediumDropdown options={siteOptions} onChange={changeSite}/></div>:
                <div className={`px-2 py-1 w-full mt-4 border flex  ${sites.length>1? "justify-between":"relative"} items-center border-neutral-300`}>
                  <svg width="25" height="25" viewBox="0 0 25 25" className="stroke-neutral-300 fill-none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M 9.45 5.25 H 7.35 C 6.793 5.25 6.2589 5.4713 5.8651 5.8651 C 5.4713 6.2589 5.25 6.793 5.25 7.35 V 19.95 C 5.25 20.507 5.4713 21.0411 5.8651 21.4349 C 6.2589 21.8288 6.793 22.05 7.35 22.05 H 17.85 C 18.407 22.05 18.9411 21.8288 19.3349 21.4349 C 19.7288 21.0411 19.95 20.507 19.95 19.95 V 7.35 C 19.95 6.793 19.7288 6.2589 19.3349 5.8651 C 18.9411 5.4713 18.407 5.25 17.85 5.25 H 15.75 M 9.45 5.25 C 9.45 5.807 9.6712 6.3411 10.0651 6.7349 C 10.4589 7.1288 10.993 7.35 11.55 7.35 H 13.65 C 14.207 7.35 14.7411 7.1288 15.1349 6.7349 C 15.5288 6.3411 15.75 5.807 15.75 5.25 M 9.45 5.25 C 9.45 4.693 9.6712 4.1589 10.0651 3.7651 C 10.4589 3.3713 10.993 3.15 11.55 3.15 H 13.65 C 14.207 3.15 14.7411 3.3713 15.1349 3.7651 C 15.5288 4.1589 15.75 4.693 15.75 5.25 M 9.45 14.7 L 11.55 16.8 L 15.75 12.6" />
                  </svg>
                    <p className={`text-sm ${dark?"text-white":"text-black"} ${sites>1?"":"w-full absolute text-center"}`}>{(site.name)}</p>
                    {sites.length>1&&<button onClick={backService}>
                      <svg width="25" height="25" viewBox="0 0 25 25" className={`${dark?"fill-white":"fill-black"}`} xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.9825 4.48295C17.2131 4.24417 17.489 4.05372 17.794 3.92269C18.099 3.79167 18.427 3.72271 18.759 3.71982C19.0909 3.71694 19.4201 3.78019 19.7274 3.90589C20.0346 4.0316 20.3138 4.21723 20.5485 4.45196C20.7832 4.68669 20.9689 4.96582 21.0946 5.27306C21.2203 5.5803 21.2835 5.9095 21.2806 6.24145C21.2777 6.5734 21.2088 6.90145 21.0778 7.20646C20.9467 7.51147 20.7563 7.78733 20.5175 8.01795L19.5263 9.0092L15.9913 5.4742L16.9825 4.48295ZM14.2237 7.2417L3.75 17.7154V21.2504H7.285L17.76 10.7767L14.2225 7.2417H14.2237Z"/>
                      </svg>
                    </button>}
                    </div>}
                  {timeSelect === true && <div className={`py-1 px-2 mt-4 w-full border flex justify-between items-center border-neutral-300`}>
                  <svg width="25" height="25" viewBox="0 0 25 25" className=" stroke-neutral-300 fill-none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 2.5C7.16848 2.5 6.85054 2.6317 6.61612 2.86612C6.3817 3.10054 6.25 3.41848 6.25 3.75V5H5C4.33696 5 3.70107 5.26339 3.23223 5.73223C2.76339 6.20107 2.5 6.83696 2.5 7.5V20C2.5 20.663 2.76339 21.2989 3.23223 21.7678C3.70107 22.2366 4.33696 22.5 5 22.5H20C20.663 22.5 21.2989 22.2366 21.7678 21.7678C22.2366 21.2989 22.5 20.663 22.5 20V7.5C22.5 6.83696 22.2366 6.20107 21.7678 5.73223C21.2989 5.26339 20.663 5 20 5H18.75V3.75C18.75 3.41848 18.6183 3.10054 18.3839 2.86612C18.1495 2.6317 17.8315 2.5 17.5 2.5C17.1685 2.5 16.8505 2.6317 16.6161 2.86612C16.3817 3.10054 16.25 3.41848 16.25 3.75V5H8.75V3.75C8.75 3.41848 8.6183 3.10054 8.38388 2.86612C8.14946 2.6317 7.83152 2.5 7.5 2.5ZM7.5 8.75C7.16848 8.75 6.85054 8.8817 6.61612 9.11612C6.3817 9.35054 6.25 9.66848 6.25 10C6.25 10.3315 6.3817 10.6495 6.61612 10.8839C6.85054 11.1183 7.16848 11.25 7.5 11.25H17.5C17.8315 11.25 18.1495 11.1183 18.3839 10.8839C18.6183 10.6495 18.75 10.3315 18.75 10C18.75 9.66848 18.6183 9.35054 18.3839 9.11612C18.1495 8.8817 17.8315 8.75 17.5 8.75H7.5Z" />
                  </svg>
                    <p className={`text-sm ${dark?"text-white":"text-black"}`}>{(date!==undefined?new Date(date).toLocaleDateString():"")}</p>
                    <button onClick={backCalendar}>
                      <svg width="25" height="25" viewBox="0 0 25 25" className={`${dark?"fill-white":"fill-black"}`} xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.9825 4.48295C17.2131 4.24417 17.489 4.05372 17.794 3.92269C18.099 3.79167 18.427 3.72271 18.759 3.71982C19.0909 3.71694 19.4201 3.78019 19.7274 3.90589C20.0346 4.0316 20.3138 4.21723 20.5485 4.45196C20.7832 4.68669 20.9689 4.96582 21.0946 5.27306C21.2203 5.5803 21.2835 5.9095 21.2806 6.24145C21.2777 6.5734 21.2088 6.90145 21.0778 7.20646C20.9467 7.51147 20.7563 7.78733 20.5175 8.01795L19.5263 9.0092L15.9913 5.4742L16.9825 4.48295ZM14.2237 7.2417L3.75 17.7154V21.2504H7.285L17.76 10.7767L14.2225 7.2417H14.2237Z"/>
                      </svg>
                    </button>
                    </div>}
                </div>
              </div>
              <div className={` ${timeSelect === false?"items-center flex justify-center":""} w-full `}>
                {timeSelect === false ?<SmallCalendar onChange={setDay} times={slots} day={"none"} changeSlots={changeSlots}/>:
                <div className=" py-4 ">
                <p className={`text-center w-full font-semibold text-base pb-6 text-neutral-300`}>{"Select time for "+(date!==undefined?new Date(date).toLocaleDateString():"")}
                  <div className=" w-full h-full pb-4 border-b border-neutral-300"></div></p>
                  <div className="flex justify-center">
                <div className="object-contain  relative overflow-y-auto overflow-x-hidden overscroll-contain">
                <div className={`grid grid-cols-1 ${times.length >11&& "pr-2"} sm:grid-cols-2 lg:grid-cols-4 gap-4`}>
                  {times}
                </div>
                </div>  
                </div>       
                </div>}
              </div>
            </div>: 
            <Fragment>
              {smallPages ===0 &&<div className={`flex flex-col h-full md:border md:border-neutral-300 rounded ${dark?"md:bg-neutral-500":"md:bg-white"}`}>
                {width<600?<Fragment><p className="text-[16px] text-center text-neutral-300 font-semibold ">Select service, date </p>
                <p className="text-[16px] text-center pb-2 text-neutral-300 font-semibold border-neutral-300 border-b">and time. </p></Fragment>:
                <p className="text-xl text-center text-neutral-300 font-semibold border-neutral-300 border-b py-4">Select service, date and time.</p>}
                <div className="py-4 md:p-2  md:mt-4">
                  <MediumDropdown options={options} onChange={changeService} back={true}/>
                  <MediumDropdown options={siteOptions} onChange={changeSite} back={true}/>
                </div>
                <button onClick={toCalendar} className={`md:bg-transparent bottom-0 text-sm p-2 py-1 md:mx-2  md:mt-4 mb-1 md:mb-4 border hover:border-accent rounded-lg ${dark?"text-white border-neutral-300 bg-neutral-900":"text-neutral-500 border-neutral-300 bg-white"}`}>Choose date</button>
              </div>}
              {smallPages===1 &&<div className="w-full h-full flex justify-center md:col-span-1 col-span-2 md:m-auto">
                {timeSelect === false ?<div className="w-full h-full flex flex-col "><SmallCalendar onChange={setDay} day={"none"} times={slots} changeSlots={changeSlots}/>
                <button onClick={toService} className={` text-sm p-2 py-1  mt-4 md:mb-4 border hover:border-accent rounded-lg ${dark?"text-white border-neutral-300 bg-neutral-900":"text-neutral-500 border-neutral-300 bg-white"}`}>Change service</button>
                </div>
                :
                <div className="lg:my-6 md:h-full  md:w-full flex flex-col">
                <div className="object-contain h-3/5 md:h-5/6 relative overflow-y-auto overflow-x-hidden overscroll-contain">
                <div className={`grid grid-cols-3 md:grid-cols-4 gap-4 md:border p-4 grid-rows-5 ${dark?"md:bg-neutral-500":"md:bg-white"} border-neutral-300`}>
                  {times}
                </div>
                </div>
                <button onClick={backCalendar} className={` bottom-0 text-sm p-2 py-1  mb-1  mt-4 md:mb-4 border hover:border-accent rounded-lg ${dark?"text-white border-neutral-300 bg-neutral-900":"text-neutral-500 border-neutral-300 bg-white"}`}>Change date</button>
                </div>}
              </div>}
            </Fragment>}
        </div>
        </div>
        </BaseBooking>
    )
}

export default ServicePage;