import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { ThemeContext } from "../context/ThemeContext";
import BaseBooking from "../components/BaseBooking";
import ColouredSolidButton from "../components/UI/ColouredSolidButton"
import { useDispatch, useSelector } from "react-redux";
import TitleColonInfo from "../components/UI/TitleColonInfo";
import { DeleteAppointment } from "../store/appointment-actions";
import { appointmentActions } from "../store/appointment-slice";
import useWindowDimensions from "../hooks/useWindowDimensions";

function AlreadyAppointmentPage(){
  const dispatch = useDispatch();
  const navigate = useNavigate();
    const { dark } = useContext(ThemeContext);
    const info = useSelector(state => state.appointment.appointment);
    const cur = useSelector(state => state.appointment.item);
    const services = useSelector(state => state.service.services);
    const {width} = useWindowDimensions();
    const [s] = useState(services!==undefined?services:[])
    console.log(info)

    const serv = s.find(s => s.id === info.service);

    const date = new Date(info.at).toLocaleDateString();
    const hours = new Date(info.at).getHours();
    const minutes = new Date(info.at).getMinutes();

    function confirmed(){
      dispatch(appointmentActions.inputService({service:info.service,at:info.at}));
      navigate("/final");
    }
    
    function deleteApp(){
      dispatch(DeleteAppointment(info));
      navigate("/contact");
    }

    return(
        <BaseBooking page={4} backButton={()=>{navigate("/")}}>
        <div className="h-full w-full flex md:items-center ">
        <div className="md:h-fit h-full w-full md:mb-16 mt-10">
                <div className={`${width<700?"object-contain h-4/6 relative overflow-y-auto overflow-x-hidden overscroll-contain":""}`}>
          <div className=" md:h-4/6 md:mx-auto  relative">
            <div className={`w-full h-full rounded-lg  absolute opacity-70 ${dark?"bg-neutral-500 ":"bg-white"}`}></div>
            <div className={`w-full h-full rounded-lg  p-4 border pb-4 border-neutral-300 relative`}>
              <p className={`font-semibold text-sm md:text-[16px] ${dark?"text-white":"text-neutral-900"}`}>You have already booked an appointment, would you like to keep it instead?</p>
            <div className="md:grid md:grid-cols-2 mx-auto gap-4 md:py-2">
            <TitleColonInfo title={"Surname"} info={cur.lastName}/>
            <TitleColonInfo title={"Email"} info={cur.email}/>
            <TitleColonInfo title={"Mobile"} info={cur.tel}/>
            <TitleColonInfo title={"Service"} info={serv.name}/>
            <TitleColonInfo title={"Date"} info={date}/>
            <TitleColonInfo title={"Time"} info={(hours<10?"0"+hours:hours)+":"+(minutes<10?"0"+minutes:minutes)}/>
            </div>
          <div className="pt-4  mx-auto w-full md:flex md:gap-20 justify-between">
          <ColouredSolidButton text={"Delete Appointment"} colour={"bg-red-1000"} onClick={deleteApp} diff={width<700}/>
          <ColouredSolidButton text={"Keep Appointment"} colour={"bg-green-1000"} onClick={confirmed} diff={width<700}/>
          </div>
            </div>
          </div>
            </div>
          </div>
        </div>
        </BaseBooking>
    )
}

export default AlreadyAppointmentPage;