import { createSlice } from "@reduxjs/toolkit";

const initialAppointmentState = {
    item: {customer: "", service: "", at:"", email:"", tel:"", lastName:"", serviceName:"" },
    appointment: null
}

const appointmentSlice = createSlice({
    name: 'appointment',
    initialState: initialAppointmentState,
    reducers: {
        inputDetails(state, action) {
            const newItem = action.payload;
            const oldItem = state.item
            state.item = {customer: oldItem.customer, service: oldItem.service, at:oldItem.at, email:oldItem.email, tel:oldItem.tel, lastName:newItem.lastName, serviceName:oldItem.serviceName};
            console.log(state.item);
        },
        inputContact(state, action) {
            const newItem = action.payload;
            const oldItem = state.item
            state.item = {customer: newItem.customer, service: oldItem.service, at:oldItem.at, email:newItem.email, tel:newItem.tel, lastName:oldItem.lastName, serviceName:oldItem.serviceName};
            console.log(state.item);
        },
        inputService(state, action) {
            const newItem = action.payload;
            const oldItem = state.item
            state.item = {customer: oldItem.customer, service: newItem.service, at:newItem.at, email:oldItem.email, tel:oldItem.tel, lastName:oldItem.lastName, serviceName:newItem.serviceName};
            console.log(state.item);
        },
        findAppointment(state, action) {
            state.appointment = action.payload.app;
        },
        default(state) { return state }
    }
});

export const appointmentActions = appointmentSlice.actions;
export default appointmentSlice;