import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { GetAppointment } from "../store/appointment-actions";
import { useEffect } from "react";
import BaseBooking from "../components/BaseBooking";
import { GetDetails, GetSite } from "../store/currentUser-actions";
import { GetServices } from "../store/service-actions";
import { appointmentActions } from "../store/appointment-slice";
import Spinner from "../components/UI/Spinner";

function GatewayPage(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const custId = useSelector((state) => state.currentUser.customerId);  
    const email = useSelector((state) => state.currentUser.email);  
    const phone = useSelector((state) => state.currentUser.phone);  
    const site = useSelector((state) => state.currentUser.site);  
    const serv = useSelector((state) => state.service.services);  
    const appointment = useSelector((state) => state.appointment.appointment)

    useEffect(()=>{
      dispatch(GetDetails());
      dispatch(GetSite());
      dispatch(GetServices());
    },[])

    useEffect(()=>{
      if(custId!=="" && serv.length !==0){
      dispatch(GetAppointment(custId));
      console.log(appointment);
      }
    },[custId,serv, dispatch])

    useEffect(()=>{
      if(appointment!==null){
        dispatch(appointmentActions.inputContact({customer:custId, tel:phone, email:email}))
          if(appointment !== "none"){
            navigate("/alreadyAppointment")
          }else{
            navigate("/contact")
          }
        }
    },[appointment, dispatch])

    return(
      <BaseBooking>
      <div className="flex justify-center items-center h-full">
      <Spinner/>
      </div>
      </BaseBooking>
    )
}

export default GatewayPage;