import { useContext, useEffect, useState} from "react";
import { ThemeContext } from "../../context/ThemeContext";
import MediumDropdownItem from "./MediumDropDownItem";

function MediumDropdown({options, onChange, link, data = null, acc=false, title = null, back = false}){
  const theme = useContext(ThemeContext);
  const {dark} = theme;
  const [open, setOpen] = useState(false);

    const menuItems = options.map((option, index) => <MediumDropdownItem name={option.name} key={option.id} id={option.id} value={option.value} acc={acc} onClick={changeController} last={index===options.length-1?true:false}/>)

    const [option, setOption] = useState(options[0].name);

    useEffect(()=>{
      setOption(options[0].name);
    },[options])

    function changeController(e){
      setOption(e.name);
      setOpen(false)
      toggleOptions();
      onChange(e.value)
    }

    function toggleOptions(){
      setOpen(!open);
    }

    return (
      <div className={`relative w-full h-fit`}>
      <div className=" grid grid-cols-1 w-full h-full z-20 ">
    <button
    className={`  ${
      dark ? "text-neutral-100  " : "text-neutral-500 "
    } ${title !== null? "bg-neutral-600 text-medium":"text-sm"} w-full h-full ${open?"rounded-x-lg rounded-t-lg":"rounded-lg"} lg:rounded space-x-2 items-center border ${acc? "border-accent" :dark? "border-neutral-300 md:bg-transparent bg-neutral-900":"border-neutral-300 md:bg-transparent bg-white"}  `}
  id="select"
  onClick={toggleOptions}>
    <div  className="flex justify-between items-center">
      <div className="h-full w-10/12 md:w-11/12 py-1 border-r border-neutral-300"><p className="text-transparent">a</p></div>
    <p className="w-full absolute">{title === null ? option: title}</p>
    <div className="h-full w-2/12 md:w-1/12 flex justify-center items-center">
    <svg data-accordion-icon className={`w-6 h-6 ${open ? "rotate-180" : ""} shrink-0 fill-accent`} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
    </div>
    </div>
  </button>
  <div>
    {open && <div className={`absolute w-full h-max space-y-2 border-b border-x z-10 ${acc?"border-accent":dark?"border-neutral-300 bg-neutral-500":"bg-white border-neutral-300"} `}><ul >{menuItems}</ul></div>}
    </div>
    </div>
  </div>)
}

export default MediumDropdown;