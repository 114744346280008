import BookingCard from "../components/Cards/BookingCard";
import TopBanner from "./UI/TopBanner";
import Logo from '../images/RMGLogo.png'
import ButtonHolder from "./ButtonHolder";

const pages = [
    {
        name: "login",
        image: "M 11.2517 8.2757 C 11.9349 8.2757 12.5903 8.0043 13.0736 7.5212 C 13.5566 7.038 13.828 6.3826 13.828 5.6994 C 13.828 5.0162 13.5566 4.3609 13.0736 3.8777 C 12.5903 3.3945 11.9349 3.1231 11.2517 3.1231 C 10.5685 3.1231 9.9132 3.3945 9.4299 3.8777 C 8.9468 4.3609 8.6755 5.0162 8.6755 5.6994 C 8.6755 6.3826 8.9468 7.038 9.4299 7.5212 C 9.9132 8.0043 10.5685 8.2757 11.2517 8.2757 Z M 5.2404 16.0045 C 5.2404 15.2152 5.3959 14.4335 5.6979 13.7042 C 6.0001 12.9748 6.4428 12.3121 7.0011 11.7539 C 7.5593 11.1957 8.222 10.7529 8.9513 10.4507 C 9.6807 10.1488 10.4624 9.9932 11.2517 9.9932 C 12.0411 9.9932 12.8228 10.1488 13.5522 10.4507 C 14.2815 10.7529 14.9442 11.1957 15.5023 11.7539 C 16.0606 12.3121 16.5034 12.9748 16.8055 13.7042 C 17.1076 14.4335 17.2631 15.2152 17.2631 16.0045 H 5.2404 Z",
        page:1
    },
    {
        name: "details",
        image: "M 5.8075 5.3783 C 5.8075 5.1866 5.8836 5.0028 6.0192 4.8672 C 6.1547 4.7316 6.3386 4.6555 6.5303 4.6555 H 8.0864 C 8.2575 4.6556 8.4229 4.7163 8.5534 4.827 C 8.6839 4.9376 8.7709 5.091 8.799 5.2598 L 9.3339 8.4652 C 9.3593 8.6178 9.3353 8.7746 9.265 8.9127 C 9.1948 9.0506 9.0821 9.1622 8.9436 9.2314 L 7.8247 9.79 C 8.226 10.7842 8.8235 11.6874 9.5816 12.4455 C 10.3397 13.2037 11.2429 13.8012 12.2372 14.2025 L 12.7966 13.0836 C 12.8656 12.9452 12.9772 12.8326 13.115 12.7624 C 13.2528 12.6922 13.4094 12.6679 13.562 12.6933 L 16.7674 13.2282 C 16.9362 13.2562 17.0895 13.3433 17.2001 13.4737 C 17.3107 13.6043 17.3716 13.7697 17.3716 13.9408 V 15.4969 C 17.3716 15.6886 17.2955 15.8724 17.1599 16.008 C 17.0244 16.1435 16.8406 16.2197 16.6488 16.2197 H 15.2034 C 10.0139 16.2197 5.8075 12.0133 5.8075 6.8239 V 5.3783 Z",
        page:2
    },
    {
        name: "appointment",
        image: "M 8.8664 4.4752 C 8.6774 4.4752 8.4961 4.5503 8.3624 4.684 C 8.2287 4.8177 8.1536 4.999 8.1536 5.188 V 5.9008 H 7.4408 C 7.0627 5.9008 6.7001 6.051 6.4327 6.3183 C 6.1654 6.5857 6.0152 6.9483 6.0152 7.3264 V 14.4544 C 6.0152 14.8325 6.1654 15.1951 6.4327 15.4625 C 6.7001 15.7298 7.0627 15.88 7.4408 15.88 H 15.9944 C 16.3725 15.88 16.7351 15.7298 17.0025 15.4625 C 17.2698 15.1951 17.42 14.8325 17.42 14.4544 V 7.3264 C 17.42 6.9483 17.2698 6.5857 17.0025 6.3183 C 16.7351 6.051 16.3725 5.9008 15.9944 5.9008 H 15.2816 V 5.188 C 15.2816 4.999 15.2065 4.8177 15.0728 4.684 C 14.9392 4.5503 14.7578 4.4752 14.5688 4.4752 C 14.3798 4.4752 14.1984 4.5503 14.0648 4.684 C 13.9311 4.8177 13.856 4.999 13.856 5.188 V 5.9008 H 9.5792 V 5.188 C 9.5792 4.999 9.5041 4.8177 9.3704 4.684 C 9.2367 4.5503 9.0554 4.4752 8.8664 4.4752 Z M 8.8664 8.0392 C 8.6774 8.0392 8.4961 8.1143 8.3624 8.248 C 8.2287 8.3817 8.1536 8.563 8.1536 8.752 C 8.1536 8.941 8.2287 9.1224 8.3624 9.256 C 8.4961 9.3897 8.6774 9.4648 8.8664 9.4648 H 14.5688 C 14.7578 9.4648 14.9392 9.3897 15.0728 9.256 C 15.2065 9.1224 15.2816 8.941 15.2816 8.752 C 15.2816 8.563 15.2065 8.3817 15.0728 8.248 C 14.9392 8.1143 14.7578 8.0392 14.5688 8.0392 H 8.8664 Z",
        page:3
    },
    {
        name: "final",
        image: "M 17.7402 6.2401 C 17.9258 6.4257 18.0301 6.6775 18.0301 6.94 C 18.0301 7.2025 17.9258 7.4543 17.7402 7.64 L 9.8202 15.56 C 9.6346 15.7455 9.3827 15.8498 9.1202 15.8498 C 8.8577 15.8498 8.606 15.7455 8.4203 15.56 L 4.4603 11.6 C 4.28 11.4132 4.1802 11.1632 4.1825 10.9036 C 4.1847 10.644 4.2888 10.3957 4.4724 10.2122 C 4.6559 10.0286 4.9042 9.9245 5.1638 9.9222 C 5.4234 9.92 5.6735 10.0197 5.8602 10.2001 L 9.1202 13.4601 L 16.3403 6.2401 C 16.526 6.0545 16.7777 5.9502 17.0402 5.9502 C 17.3027 5.9502 17.5546 6.0545 17.7402 6.2401 Z",
        page:4
    },
]

function BaseBooking(props){

    return(
        <BookingCard>
                <TopBanner logo={Logo} pages={pages} currentPage={props.page}></TopBanner>
                <div className="w-2/3 h-3/4 m-auto space-y-8">
                    {props.children}
                </div>
                <ButtonHolder backButton={props.backButton} forwardButton={props.forwardButton} submit={props.submit}/>
        </BookingCard>
        )
}

export default BaseBooking;