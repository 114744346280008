import { redirect, Form, useNavigation } from "react-router-dom";
import { useContext, useState } from "react";
import { ThemeContext } from "../context/ThemeContext";
import BaseBooking from "../components/BaseBooking";
import UnderlineTextInput from "../components/UI/UnderlineTextInput";
import { useDispatch } from "react-redux";
import { appointmentActions } from "../store/appointment-slice";

function AuthPage({  }) {
  const dispatch = useDispatch();
  const { dark } = useContext(ThemeContext);
  const [surname, setSurname] = useState();
  const [ucrn, setUcrn] = useState();

  function enter(){
    dispatch(appointmentActions.inputDetails({lastName:surname}))
  }

  function changeSurname(e){
    setSurname(e.target.value);
  }
  
  function changeUcrn(e){
    setUcrn(e.target.value);
  }

  return (
    <Form className="w-full h-full flex justify-center items-center" method="post">
    <BaseBooking page={1} forwardButton={enter} submit={true}>
      <div className="h-full w-full flex items-center ">
      <div className="w-full h-fit md:m-auto mb-10 ">
        <div className=" flex flex-col items-center ">
          <p className={`md:text-2xl text-lg text-center font-semibold md:mb-4 ${dark?"text-white":"text-black"}`}>BOOK YOUR APPOINTMENT</p>
          <UnderlineTextInput id={"lastName"} name={"Surname"} onChange={changeSurname} info={"Your surname as it appears on your invitation."}/>
          <UnderlineTextInput id={"ucrn"} name={"UCRN"} onChange={changeUcrn} info={"This is the 8-10 character reference number and can be found on your mailer, email or text message. "}/>
        </div>
      </div>
      </div>
    </BaseBooking>
      </Form>
  );
}

export default AuthPage;

export async function A({ request }) {
  const data = await request.formData();
  const authData = {
    "lastName": data.get("lastName"),
    "ucrn": data.get("ucrn"),
  };

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(authData),
  };
  const res = await fetch(process.env.REACT_APP_API_URL+"/Authentication/Login", requestOptions);

  if (res.status === 401) {
    alert("Details not recognised");
    return redirect("/");
  }

  const resData = await res.json();

  localStorage.setItem("token", resData.token);
  localStorage.setItem("expiration", resData.expiration);

  return redirect("/gateway");
}

export function fakeToken() {
  const token = "fake_Token";
  localStorage.setItem("token", token);
  const expiration = new Date();
  expiration.setHours(expiration.getHours() + 1);
  localStorage.setItem("expiration", expiration.toISOString());
  return redirect("/");
}