import { useContext, useState} from "react";
import { ThemeContext } from "../../context/ThemeContext";

function MediumDropdownItem({id, name, value, onClick, last}){
  const theme = useContext(ThemeContext);
  const {dark} = theme;
  const [hover, setHover] = useState(false);

    function changeController(){
      onClick({name, value})
    }

    function hoverOn(){
      setHover(true)
    }
    function hoverOff(){
      setHover(false)
    }

    return (
    <li onMouseEnter={hoverOn} onMouseLeave={hoverOff} className={`py-2 h-full w-full flex justify-center text-sm ${last&&" rounded-b-lg "} ${dark ? " text-neutral-100  hover:bg-neutral-800" : " text-neutral-500 hover:bg-neutral-100"}`}>
      <div className="w-full h-full flex justify-between">
        <button className="text-center w-full " onClick={changeController} value={value}>
        {name}
      </button>
      <div></div>
      </div>
    </li>
    )
}

export default MediumDropdownItem;