import { useEffect, useState } from "react";
import SmCalTitle from "./SmCalTitle";
import getDaysInMonth from "../../hooks/getAllDaysInMonth";
import SmCalDay from "./SmCalDay";
import { useContext} from "react";
import { ThemeContext } from "../../context/ThemeContext";

const days = [
    {
        name: "Sun"
    },
    {
        name: "Mon"
    },
    {
        name: "Tues"
    },
    {
        name: "Wed"
    },
    {
        name: "Thurs"
    },
    {
        name: "Fri"
    },
    {
        name: "Sat"
    },
]
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

function SmallCalendar({useRange, onChange, times, changeSlots}){
    const dayTitles = days.map(day => <SmCalTitle key={day.name} name={day.name}/>)
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    const [month, setMonth] = useState(currentMonth);
    const [year, setYear] = useState(currentYear);
    const theme = useContext(ThemeContext);
    const {dark} = theme;
    const [selectedDay, setSelectedDay] = useState("none");

    useEffect(()=>{
            onChange(selectedDay);
    },[selectedDay, useRange, onChange])

    useEffect(()=>{
        
        changeSlots(new Date(year,month,1));
    },[month, year])

    function next() {
        if(month === 11){
            setMonth(0);
            setYear(prevState => (prevState+1));
        }
        else{
            setMonth(prevState => (prevState+1));
        }
    }
    
    function previous() {
        if(month === 0){
            setMonth(11);
            setYear(prevState => (prevState-1));
        }
        else{
            setMonth(prevState => (prevState-1));
        }
    }

    function selectManager(day){
            if(day === selectedDay){
                setSelectedDay("none")
            }
            else{
                setSelectedDay(day);
            }
    }

    let firstDay = (new Date(year, month)).getDay();
    let daysInMonth = getDaysInMonth(month, year);

    const populatedMonth = [];

    // creating all cells
    let date = 1;
    let extra = 1;
    for (let i = 0; i < 42; i++) {
        if(i >= firstDay && i - firstDay<daysInMonth.length)
        {
            if(date >= today.getDate()){
                const todaySlots = times.filter(t => new Date(t.slotStart).getDate() === date)
                if(todaySlots.length!==0){
                    populatedMonth.push(<SmCalDay key={i} id={new Date(year,month,date).toJSON()} day={date} onClick={selectManager} act={selectedDay}/>)
                }else{
                    populatedMonth.push(<SmCalDay key={i} id={new Date(year,month,date).toJSON()} day={date} current={false} strike={true}/>)
                }
            }
            else if(month === currentMonth && year === currentYear){
                populatedMonth.push(<SmCalDay key={i} id={new Date(year,month,date).toJSON()} day={date} current={false}/>)
            }else{
                const todaySlots = times.filter(t => new Date(t.slotStart).getDate() === date)
                if(todaySlots.length!==0){
                    populatedMonth.push(<SmCalDay key={i} id={new Date(year,month,date).toJSON()} day={date} onClick={selectManager} act={selectedDay}/>)
                }else{
                    populatedMonth.push(<SmCalDay key={i} id={new Date(year,month,date).toJSON()} day={date} current={false} strike={true}/>)
                }
            }
            
            date++;
        }
        else{
            if(i<firstDay){
                let m = month -1;
                let y = year;
                if(m ===-1){
                    m=11;
                    y = year -1;
                }
                let monthLength = getDaysInMonth(m, y);
                let d = monthLength.length - firstDay +i;
                populatedMonth.push(<SmCalDay key={i} id={new Date(y,m,d)} day={d} current={false}/>)
            }else{
                let m = month +1;
                let y = year;
                if(m === 12){
                    m=0;
                    y= year+1;
                }
                populatedMonth.push(<SmCalDay key={i} id={new Date(y,m,extra)} day={extra} current={false}/>)
                extra++;
            }
        }
        if (date === today.getDate() && year === today.getFullYear() && month === today.getMonth()) {
                    
        } // color today's date
    }

    const monthYearCheck = new Date(year,month);
    
    return(
        <div className={`w-full md:my-2 h-fit ${dark?"bg-neutral-900 md:bg-neutral-500":"bg-white "}  border border-neutral-300 md:border-transparent md:bg-transparent rounded`}>
    <div className='w-full max-w-lg md:px-8 sm:px-2  mx-auto rounded-2xl flex flex-col'>
        <div className="flex justify-between md:p-2 mx-2">
{monthYearCheck > today ? <div className="-rotate-90 cursor-pointer" onClick={previous}>
    <svg className={`stroke-accent lg:hover:stroke-neutral-900`} width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.001 6L6.00098 1L1.00098 6"   strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
 </div>:<div></div>}
<span className={`uppercase text-sm font-semibold  ${dark? "text-white" : "text-neutral-900"}`}>{months[month] + " - " + year}</span>
<div className="rotate-90 cursor-pointer" onClick={next}>
    <svg className={`stroke-accent lg:hover:stroke-neutral-900`} width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.001 6L6.00098 1L1.00098 6"   strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
</div>
</div>
       <div className="px-2 ">
    </div>
 <div className="grid grid-cols-7 justify-between text-xs md:text-sm pt-4  gap-2 ">
       {dayTitles} 
 </div>

 <div className="grid justify-between font-medium text-sm gap-x-2 md:gap-y-0 gap-y-2 grid-cols-7 ">
        {populatedMonth}
 </div>
 </div>
</div>
    )
}

export default SmallCalendar;