import { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";

function BookingCard(props){
    const { dark } = useContext(ThemeContext);

    return(
        <div className={`md:w-3/5 w-3/4 h-4/5 m-auto rounded-xl relative shadow-2xl ${dark?"shadow-black":"shadow-neutral-500"}`}>
        <div className={`w-full h-full  absolute rounded-xl border-2 border-accent border-opacity-100 ${dark?"bg-neutral-900 bg-opacity-70":"bg-neutral-100 bg-opacity-70 "}`}></div>
        <div className={`w-full h-full absolute `}>{props.children}</div>
        </div>
    )
}

export default BookingCard;