import { organisationActions } from "./organisation-slice";

export function GetOrganisationData(org) {
    return async (dispatch) => {
        const fetchLogo = async () => {
            var headers = {
                "Content-Type": "application/json",
                "Authorization": "bearer " + localStorage.getItem("token")
            }

            var requestOptions = {
                method: 'POST',
                headers: headers,
                redirect: 'follow'
            };

            const response = await fetch(process.env.REACT_APP_API_URL+"/Organisation/GetLogo?id=" + org,
                requestOptions
            );

            if (!response.ok) {
                throw new Error('Could not fetch logo.');
            }

            if (response.status === 204) {
                return "";
            }

            const data = await response.json();

            return data;
        }
        const orgData = await fetchLogo();
        dispatch(organisationActions.replaceOrganisation({ logo: orgData.key || "", colour: "#A01D22", secondaryColour: "#FFFFFF"}))

    }
};

export function GetSites() {
    return async (dispatch) => {
        const fetchSites = async () => {
            var headers = {
                "Content-Type": "application/json",
                "Authorization": "bearer " + localStorage.getItem("token")
            }

            var requestOptions = {
                method: 'GET',
                headers: headers,
                redirect: 'follow'
            };

            const response = await fetch(process.env.REACT_APP_API_URL+"/Organisations/GetManySites",
                requestOptions
            );

            if (!response.ok) {
                throw new Error('Could not fetch sites.');
            }

            if (response.status === 204) {
                return "";
            }

            const data = await response.json();

            return data;
        }
        const orgData = await fetchSites();
        dispatch(organisationActions.replaceSites(orgData))

    }
};