import { useContext} from "react";
import { ThemeContext } from "../../context/ThemeContext";
import SmallDropdown from "./SmallDropdown";
import { useState } from "react";

function UnderlineMobileInput({name, onChange, value, id}){
    const theme = useContext(ThemeContext);
    const [prefix, setPrefix] = useState(10);
    const [val, setVal] = useState(value);
    const {dark} = theme;    
    
    const options = [
        {
            name: "+44",
            id: "eng",
            value: 10
        },
    ]

    function changePrefix(v){
        setPrefix(v);
    }

    const handleChange = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setVal(value);
        onChange(value);
      };
      

    function isNumberKey(evt) {
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57))
          return false;
        return true;
      }

    return(
    <div className={`w-full relative my-6`}>
        <div className="flex ">
        <p className={`font-medium text-sm md:text-lg ${dark?"text-white":"text-black"}`}>{name}</p>
        </div>
        <div className="w-full flex h-full gap-2">
            <div className={`border-2 h-full text-sm grow mt-1.5 ${dark?"border-white":"border-black"}`}>
                <SmallDropdown options={options} onChange={changePrefix}/>
            </div>
            <div className={`border-b-2 h-min w-full ${dark?"border-white":"border-black"}`}>
        <input required type={"tel"} name={id} maxLength={prefix} onKeyDown={isNumberKey} onChange={handleChange} value={val!==""?val:value} className={`${dark ? "  text-white" : " text-neutral-900" } rounded-lg bg-transparent border-none text-sm block w-full`} />
        </div>
        </div>
    </div>
    )
}

export default UnderlineMobileInput;