function ColouredSolidButton({text, onClick, type, disabled, colour, diff = false}) {

    return (
      <button
        type={type !== null? type :"button"}
        className={`${colour} disabled:bg-neutral-500 flex justify-center text-white text-center ${!disabled && 'hover:-translate-y-1'} focus:ring-4 focus:outline-none w-full focus:ring-neutral-300 font-medium rounded-lg ${diff?"text-xs":"text-sm"} text-center mb-2`}
        onClick={onClick}
        disabled={disabled}
      >
        <p className="px-5 py-1.5">{text}</p>
      </button>
    );
  }
  
  export default ColouredSolidButton;