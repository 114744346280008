import { useContext} from "react";
import { ThemeContext } from "../../context/ThemeContext";
import InformationIcon from "./InformationIcon";

function UnderlineTextInput({name, info, onChange,id, value}){
    const theme = useContext(ThemeContext);
    const {dark} = theme;

    return(
    <div className={`border-b-2 w-full ${dark?"border-white":"border-black"} relative my-4`}>
        <div className="flex ">
        <p className={`text-sm md:text-lg font-medium ${dark?"text-white":"text-black"}`}>{name}</p>
        {info !== null && info !== undefined && 
        <InformationIcon info={info}/>}
        </div>
        <input required type={"text"} name={id} id={id} onChange={onChange} value={value} className={`${dark ? "  text-white" : " text-neutral-900" } bg-transparent border-none text-sm rounded-lg block w-full md:p-2.5`} />
    </div>
    )
}

export default UnderlineTextInput;