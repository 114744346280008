import getUniqueId from "../hooks/getUniqueId";
import { currentUserActions } from "./currentUser-slice";

export function GetDetails() {
  return async (dispatch) => {
    const fetchData = async () => {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var requestOptions = {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/Authentication/GetContactDetails",
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not fetch details.');
      }
      const data = await response.json();

      return data;
    }

    const details = await fetchData();
    dispatch(currentUserActions.replaceCurrentUser({ email: details.email||null, phone: details.phoneNumber||null, customerId: details.customerId||null}))


  }
};
export function GetSite() {
  return async (dispatch) => {
    const fetchData = async () => {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var requestOptions = {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/Organisations/GetSite",
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not fetch site.');
      }
      const data = await response.text();

      return data;
    }

    const site = await fetchData();
    dispatch(currentUserActions.replaceSite({site:site||null}))
  }
};

export function UpdateContact(data) {
  return async (dispatch) => {
    const fetchContact = async (id) => {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var requestOptions = {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/Customers/GetContact?id="+id,
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not fetch contact.');
      }
      const data = await response.json();

      return data;
    }
    
    const createContact = async (v) => {
  
      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }
      var raw = JSON.stringify(v);
  
      var requestOptions = {
        method: 'POST',
        headers: headers,
        redirect: 'follow',
        body: raw
      };
        
      const response = await fetch(process.env.REACT_APP_API_URL+"/Customers/CreateContacts",
        requestOptions
      );
  
      if (!response.ok) {
        throw new Error('Could not create contact.');
      }
      const data = await response.json();
  
      return data;
    };

    const updateContact = async (v) => {
    
      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }
      var raw = JSON.stringify(v);
    
      var requestOptions = {
        method: 'PUT',
        headers: headers,
        redirect: 'follow',
        body: raw
      };
          
      const response = await fetch(process.env.REACT_APP_API_URL+"/Customers/UpdateContacts",
        requestOptions
      );
    
      if (!response.ok) {
        throw new Error('Could not update contact.');
      }
    };

    const contact = await fetchContact(data.customerId);

    if(contact !== null && contact !== undefined){
      contact.email = data.email;
      contact.tel = data.tel;
      contact.modifiedBy = "system";
      contact.modifiedAt = new Date().toJSON();
      await updateContact(contact);
    }
    else{
      const model = {
        id: getUniqueId(),
        email: data.email,
        tel: data.tel,
        createdAt: new Date().toJSON(),
        createdBy: "system",
        customer: data.customerId
      }
      await createContact(model);
    }
  }
};