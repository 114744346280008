import * as sig from "@microsoft/signalr";
import { useDispatch } from "react-redux";
import { serviceActions } from "./store/service-slice";

//process.env.REACT_APP_API_URL.concat("/signal")
const Conn= () =>{
    const dispatch = useDispatch();

    if (localStorage.getItem("token") != null) {

        const hubConnection = new sig.HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_API_URL.concat("/signal"), { accessTokenFactory: () => localStorage.getItem("token") })
            .withAutomaticReconnect()
            .configureLogging(sig.LogLevel.Information)
            .build();

        hubConnection.start();

        hubConnection.on('RemoveTimeslot', message => {
            const list = message.split("%");
        
            console.log(list);
            dispatch(serviceActions.removeFromTimes({start:list[0],end:list[1]}));
        });
    }
}

export default Conn;