import { redirect } from "react-router-dom";

export function getTokenDuration(){
    const storedExpirationDate = localStorage.getItem('expiration')
    const expirationDate = new Date(storedExpirationDate);
    const now = new Date();
    const duration = expirationDate.getTime() - now.getTime();
    return duration;
}

export function getAuthToken(){
    const token = localStorage.getItem('token');

    if(!token || token === undefined){
        return null;
    }

    const tokenDuration = getTokenDuration();

    if(tokenDuration <0 || tokenDuration === undefined){
        return null;
    }

    return token;
}

export function tokenLoader(){
    return getAuthToken();
}

export async function checkToken()
{
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "bearer " + localStorage.getItem("token")
    },
  };
  const res = await fetch(process.env.REACT_APP_API_URL+"/Authentication/Validate", requestOptions);

  if(res.status === 200){
    const token = getAuthToken();

    if(token === null){
        return redirect('/');
    }else{
        return null;
    }
  }else{
    return redirect('/');
  }
}