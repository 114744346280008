import { useContext} from "react";
import { ThemeContext } from "../../context/ThemeContext";

function CircleImage({image, active, name, page}){
    const theme = useContext(ThemeContext);
    const {dark} = theme;
    return(
        <div className="md:w-10 md:h-10 w-7 h-7 z-30 flex justify-center rounded-full aspect-square border-2 border-accent overflow-hidden">
            <svg viewBox="0 0 23 21"  className={` ${active >= page ? "fill-accent":" fill-none"} ${dark?" bg-black":"bg-white"}`}>
                <path fillRule="evenodd" clipRule="evenodd" d={image} />
            </svg>
        </div>
    )
}

export default CircleImage;