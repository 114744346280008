import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";
import BaseBooking from "../components/BaseBooking";
import ColouredSolidButton from "../components/UI/ColouredSolidButton"
import { useDispatch, useSelector } from "react-redux";
import TitleColonInfo from "../components/UI/TitleColonInfo";
import { CreateAppointment } from "../store/appointment-actions";
import getUniqueId from "../hooks/getUniqueId";

function ConfirmationPage(){
  const dispatch = useDispatch();
  const navigate = useNavigate();
    const { dark } = useContext(ThemeContext);
    const info = useSelector(state => state.appointment.item);

    const date = new Date(info.at).toLocaleDateString();
    const hours = new Date(info.at).getHours();
    const minutes = new Date(info.at).getMinutes();

    function confirmed(){
      const appointment = {
        id: getUniqueId(),
        withUser: "none",
        service: info.service,
        at: info.at,
        customer: info.customer,
        createdAt: new Date().toJSON(),
        createdBy: "system",
        modifiedAt: new Date().toJSON(),
        modifiedBy: "none"
      }
      console.log(appointment);
      let myPromise = new Promise(function(myResolve, myReject) {
        dispatch(CreateAppointment(appointment));

          myResolve(); // when successful
          myReject();  // when error
        });
      myPromise.then(
        function(){navigate("/final")}
      )
    }

    return(
        <BaseBooking page={4}>
        <div className="h-full w-full flex items-center">
        <div className="h-fit w-full">
          <div className="md:w-1/2  h-4/6 mx-auto  relative">
            <div className={`w-full h-full absolute opacity-70 ${dark?"bg-neutral-500 ":"bg-white"}`}></div>
            <div className={`w-full h-full px-4 border md:p-2 pb-4 border-neutral-300 relative`}>
              <p className={`font-semibold text-lg ${dark?"text-white":"text-neutral-900"}`}>Appointment Details</p>
            <div className="grid grid-cols-2 mx-auto gap-4 md:py-2">
            <TitleColonInfo title={"Surname"} info={info.lastName}/>
            <TitleColonInfo title={"Email"} info={info.email}/>
            <TitleColonInfo title={"Mobile"} info={info.tel}/>
            <TitleColonInfo title={"Service"} info={info.serviceName}/>
            <TitleColonInfo title={"Date"} info={date}/>
            <TitleColonInfo title={"Time"} info={(hours<10 ?"0"+hours:hours)+":"+(minutes <10 ?"0"+minutes:minutes)}/>
            </div>
          <div className="pt-1 md:pt-0 mx-auto w-3/4">
          <ColouredSolidButton text={"Confirm Appointment"} colour={"bg-green-1000"} onClick={confirmed}/>
          </div>
            </div>
          </div>
        <div className="flex justify-between items-center mt-2">
          <div className="w-2/6 md:w-1/6">
            <Link to={"/appointment"}>
            <ColouredSolidButton text="Back" colour="bg-red-1000" />
            </Link>
          </div>
          </div>
          </div>
        </div>
        </BaseBooking>
    )
}

export default ConfirmationPage;