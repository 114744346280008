import { useSelector } from "react-redux";
import hexToHSL from "../hooks/hexToHsl";
import { Outlet } from "react-router-dom";

function Rootpage(){  

    const accentColour = useSelector((state) => state.organisation.colour);
    const secondaryColour = useSelector((state) => state.organisation.secondaryColour);
  
    const acc = hexToHSL(accentColour);
    const sec = hexToHSL(secondaryColour);
  
    var bodyStyles = document.body.style;
    bodyStyles.setProperty('--accent-color-h', acc.h);
    bodyStyles.setProperty('--accent-color-s', acc.s + '%');
    bodyStyles.setProperty('--accent-color-l', acc.l + '%');
  
    bodyStyles.setProperty('--secondaryaccent-color-h', sec.h);
    bodyStyles.setProperty('--secondaryaccent-color-s', sec.s + '%');
    bodyStyles.setProperty('--secondaryaccent-color-l', sec.l + '%');

    return(
        <div className="w-full h-full flex">
            <Outlet />
        </div>
    )
}

export default Rootpage;