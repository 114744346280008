import { useNavigate } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import { ThemeContext } from "../context/ThemeContext";
import BaseBooking from "../components/BaseBooking";
import UnderlineTextInput from "../components/UI/UnderlineTextInput";
import UnderlineMobileInput from "../components/UI/UnderlineMobileInput";
import { useDispatch, useSelector } from "react-redux";
import { appointmentActions } from "../store/appointment-slice";
import { GetDetails, UpdateContact } from "../store/currentUser-actions";
import { GetSite } from "../store/currentUser-actions";

function InitialPage(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { dark } = useContext(ThemeContext);
    const site = useSelector((state) => state.currentUser.site);  
    const phone = useSelector((state) => state.currentUser.phone);  
    const email = useSelector((state) => state.currentUser.email);  
    const custId = useSelector((state) => state.currentUser.customerId);  
    const [tel, setTel] = useState(phone);
    const [address, setAddress] = useState(email);

    console.log(site);
    
    useEffect(()=>{
      dispatch(GetDetails());
        if (site !== "") {
            setTel(phone);
            setAddress(email);
            //dispatch(GetOrganisationData(site));
        }
    },[site])

    function validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    }
  
    function enter(){
      if(tel !== undefined && address !== undefined && tel !== "" && address !== ""){
        if(validateEmail(address)){
          if(tel !== phone || address !== email){
            dispatch(UpdateContact({tel:tel,email:address,customerId:custId}))
          }
          dispatch(appointmentActions.inputContact({customer:custId, tel:tel, email:address}))
          navigate("/appointment")
        }
      }
    }
  
    function changeTel(e){
        setTel(e);
    }
    
    function changeAddress(e){
        setAddress(e.target.value);
    }

    return(
        <BaseBooking page={2} forwardButton={enter}>
        <div className="h-full w-full flex ">
        <div className="h-fit w-full sm:m-auto">
          <div className=" flex flex-col items-center ">
            <p className={` text-2xl font-semibold text-transparent`}>a</p>
            <UnderlineMobileInput name={"Mobile Number"} id={"phone"} onChange={changeTel} value={tel}/>
            <UnderlineTextInput name={"Email Address"} id={"email"}  onChange={changeAddress} value={address}/>
          </div>
          <div className="flex justify-end">
          </div>
          </div>
        </div>
        </BaseBooking>
    )
}

export default InitialPage;