import { useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";
import BaseBooking from "../components/BaseBooking";
import { useSelector } from "react-redux";
import useWindowDimensions from "../hooks/useWindowDimensions";

function FinalPage(){
  const info = useSelector(state => state.appointment.item);
    const { dark } = useContext(ThemeContext);
    const {height} = useWindowDimensions();

    const date = new Date(info.at).toLocaleDateString();
    const hours = new Date(info.at).getHours();
    const minutes = new Date(info.at).getMinutes();

    return(
        <BaseBooking page={0}>
        <div className="h-full w-full relative">
          <div className={`w-full h-full mt-10`}>
        {height>700&&<svg className={`m-auto flex w-[152px] h-[152px] md:w-[254px] md:h-[254px] ${dark?"stroke-white":"stroke-black"}`} viewBox="0 0 254 254" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M84.6667 74.0833V31.75M169.333 74.0833V31.75M31.75 93.0753H222.25M52.9167 222.25H201.083C206.697 222.25 212.081 220.02 216.05 216.05C220.02 212.081 222.25 206.697 222.25 201.083V74.0833C222.25 68.4696 220.02 63.0858 216.05 59.1162C212.081 55.1467 206.697 52.9167 201.083 52.9167H52.9167C47.3029 52.9167 41.9191 55.1467 37.9496 59.1162C33.9801 63.0858 31.75 68.4696 31.75 74.0833V201.083C31.75 206.697 33.9801 212.081 37.9496 216.05C41.9191 220.02 47.3029 222.25 52.9167 222.25Z" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <svg x="35%" y="50%" width="86" height="63" viewBox="0 0 86 63" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 31.5L30.3333 58L81 4.99998" stroke="#00BB61" strokeWidth="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        </svg>}
        <div className={`${height>700? "mt-10":""} w-full`}>
        <p className={`text-center text-lg md:text-2xl  font-semibold ${dark?"text-white":"text-black"}`}>Thank you for booking an appointment.</p>
        <div className="flex items-center md:m-4 justify-center h-fit">
          <p className={`w-full pr-4 text-right text-lg md:text-2xl font-semibold ${dark?"text-white":"text-black"}`}>{date}</p>
          <div className="h-10 w-1 bg-green-1000 rounded-lg text-transparent">a</div>
          <p className={`text-left pl-4 w-full justify-self-start text-lg md:text-2xl font-semibold ${dark?"text-white":"text-black"}`}>{(hours<10?"0"+hours:hours)+":"+(minutes<10?"0"+minutes:minutes)}</p>
        </div>
        <p className={`md:absolute md:w-full text-center pt-2 text-xs md:text-sm ${dark?"text-white":"text-black"}`}>You will receive an email and text message confirmation and a text message reminder before your appointment. Please call 01325776411 if you do not receive this. Please close this window.</p>
        </div>
        </div>
        </div>
        </BaseBooking>
    )
}

export default FinalPage;