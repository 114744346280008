import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import RootPage from "./pages/RootPage";
import { ThemeContext } from './context/ThemeContext';
import AuthPage, { A as authAction } from "./pages/AuthPage";
import { useState } from "react";
import ErrorPage from "./pages/ErrorPage";
import useDarkMode from "./hooks/useDarkMode";
import { tokenLoader, checkToken } from "./utils/auth";
import InitialPage from "./pages/InitialPage";
import "./App.css";
import ServicePage from "./pages/ServicePage";
import ConfirmationPage from "./pages/ConfirmationPage";
import FinalPage from "./pages/FinalPage.js";
import AlreadyAppointmentPage from "./pages/AlreadyAppointmentPage.js";
import GatewayPage from "./pages/GatewayPage.js";

function App() {
  const { darkMode, toggle } = useDarkMode()

  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootPage toggle={toggle} />,
      id: "root",
      errorElement: <ErrorPage />,
      loader: tokenLoader,
      children: [
        { path: "contact", element: <InitialPage/>, },
        { index: true, element: <AuthPage toggle={toggle}/>, action: authAction },
        { path: "appointment", element: <ServicePage/>,  loader: checkToken},
        { path: "conf", element: <ConfirmationPage/>,  loader: checkToken},
        { path: "gateway", element: <GatewayPage/>,  loader: checkToken},
        { path: "alreadyAppointment", element: <AlreadyAppointmentPage/>, loader: checkToken},
        { path: "final", element: <FinalPage/>, loader: checkToken},
      ]
    }
  ]);

  let dark = darkMode;
  localStorage.setItem('theme', dark)

  return (
    <ThemeContext.Provider value={{ dark }}>      
      <div className={`h-screen w-full ${dark?" darkBackground ":" lightBackground "}`} >
        <RouterProvider router={router} />
      </div>
    </ThemeContext.Provider>
  );
}

export default App;
