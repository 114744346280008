import { useContext} from "react";
import { ThemeContext } from "../../context/ThemeContext";

function CapsuleButton({name, onClick, value, active, disabled}){
    const theme = useContext(ThemeContext);
    const {dark} = theme;

    function select(){
        onClick(value);
    }

    return( 
        <button disabled={disabled} onClick={select} value={value} className={` ${dark?"md:bg-transparent bg-neutral-900":"md:bg-transparent bg-white"} border rounded-lg flex justify-center text-sm text-center py-1 h-fit ${disabled? "border-transparent text-neutral-300":active === value? dark?"border-green-1000 text-white": "text-neutral-500 border-green-1000 ":dark?"text-white border-neutral-300 hover:border-green-1000":"text-neutral-500 border-neutral-300 hover:border-green-1000"}`}>
            <p className="px-2">{name}</p>
        </button>
    )
}

export default CapsuleButton;