import { appointmentActions } from "./appointment-slice";

export function CreateAppointment(val) {
  return async (dispatch) => {
    const create = async (v) => {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }
      var raw = JSON.stringify(v);

      var requestOptions = {
        method: 'POST',
        headers: headers,
        redirect: 'follow',
        body: raw
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/Appointment/Create",
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not post appointment.');
      }
      const data = await response.json();

      return data;
    };

    console.log(val);
    await create(val);
  }
};

export function GetAppointment(val) {
  return async (dispatch) => {
    const get = async (v) => {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var requestOptions = {
        method: 'GET',
        headers: headers,
        redirect: 'follow',
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/Appointment/Get?id="+v,
        requestOptions
      );

      if (!response.ok) {
        return null;
      }

      if(response.status === 204){
        return null;
      }

      const data = await response.json();

      return data;
    };
    const appointment = await get(val);
    console.log(appointment)
    dispatch(appointmentActions.findAppointment({app:appointment||"none"}))
    return appointment;
  }
};

export function DeleteAppointment(val) {
  return async (dispatch) => {
    const deleteAppointment = async (v) => {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var raw = JSON.stringify(v);

      var requestOptions = {
        method: 'DELETE',
        headers: headers,
        redirect: 'follow',
        body: raw
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/Appointment/Delete",
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not delete appointment.');
      }
    };

    await deleteAppointment(val);
  }
};