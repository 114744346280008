import { serviceActions } from "./service-slice";

export function GetServices() {
  return async (dispatch) => {
    const fetchServices = async () => {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var requestOptions = {
        method: 'GET',
        headers: headers,
        redirect: 'follow',
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/Services/ReadMany",
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not get services.');
      }
      const data = await response.json();

      return data;
    };

    const services = await fetchServices();
    
    dispatch(serviceActions.replace({services: services||null}))
  }
};
export function GetTimeSlots(date) {
  return async (dispatch) => {
    const fetchTimes = async () => {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }
      var raw = JSON.stringify(date);

      var requestOptions = {
        method: 'POST',
        headers: headers,
        redirect: 'follow',
        body: raw
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/Appointment/Availability",
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not get time slots.');
      }
      const data = await response.json();

      return data;
    };

    const slots = await fetchTimes();

    
    dispatch(serviceActions.replaceTimes({timeSlots: slots||null}))
  }
};
export function TakeTimeSlot(value) {
  return async (dispatch) => {
    const fetchTimes = async () => {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token"),
        "AuthApiKey":process.env.REACT_APP_AUTH_API_KEY
      }
      var raw = JSON.stringify(value);

      console.log(headers);

      var requestOptions = {
        method: 'POST',
        headers: headers,
        redirect: 'follow',
        body: raw
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/Signal/TakeSlot",
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not reserve slot');
      }
    };
    fetchTimes();
  }
};