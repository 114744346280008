import { Fragment, useContext, useEffect, useState} from "react";
import { ThemeContext } from "../../context/ThemeContext";

function SmCalDay({day,id, onClick, range, act, current = true, strike = false}){
    const theme = useContext(ThemeContext);
    const {dark} = theme;
    const [selected, setSelected] = useState(false);
    const [sPos, setSPos] = useState(1);

    useEffect(()=>{
        if(range === true){
            if(act.includes(id.toString())){
            setSelected(true)
            if(act[0] === id.toString()){
                setSPos(0);
            }else if(act[act.length-1] === id.toString()){
                setSPos(2);
            }else{
                setSPos(1);
            }
            }
            else{
                setSelected(false);
            }
        }else{
        if(id.toString() !== act){
        setSelected(false);
        }
        else{
            setSelected(true)
        }
    }
    },[act, day])

    function selectManager(e){
        if(onClick != null){
            onClick(e.target.id);
        }

    }
    return(
        <Fragment>
            <span id={id} className={`${selected ? " bg-green-1000 text-secondary " :"px-1" } rounded-full aspect-square  flex justify-center items-center cursor-pointer ${!selected&& dark ? current ? "text-white  hover:text-green-1000 " : "text-neutral-300 font-normal" : current?"text-black  hover:text-green-1000 ": "text-neutral-300 font-normal"} `}  onClick={selectManager}>
                {strike&&<p className="text-red-1000 absolute text-lg font-bold rotate-12 pb-1 pl-0.5">/</p>}
                {day}
            </span>
            </Fragment>
    )
}

export default SmCalDay;