import { configureStore } from "@reduxjs/toolkit";
import currentUserSlice from "./currentUser-slice";
import organisationSlice from "./organisation-slice";
import appointmentSlice from "./appointment-slice";
import serviceSlice from "./service-slice";

const store = configureStore({
    reducer: { currentUser: currentUserSlice.reducer, organisation: organisationSlice.reducer, appointment: appointmentSlice.reducer, service: serviceSlice.reducer}
})

export default store;