import { createSlice } from "@reduxjs/toolkit";

const initialCurrentUserState = {
    customerId: "",
    email: "",
    phone: "",
    site: ""
}

const currentUserSlice = createSlice({
    name: 'currentUser',
    initialState: initialCurrentUserState,
    reducers: {
        replaceCurrentUser(state, action) {
            state.customerId = action.payload.customerId;
            state.email = action.payload.email;
            state.phone = action.payload.phone;
        },
        replaceSite(state, action) {
            state.site = action.payload.site;
        },
        changeDetails(state, action) {
            const newItem = action.payload;
            state.item = newItem;
        },
        changePassword(state, action) {
            const newItem = action.payload;
            state.changed = true;
            state.item.push({ id: newItem.id, body: newItem.body });
        },
        default(state) { return state }
    }
});

export const currentUserActions = currentUserSlice.actions;
export default currentUserSlice;